$(function() {
  // 並び替え
  $('.js-search_study_sorting').on('change', function() {
    const value = $(this).val();
    const form = $('#search_study_sorting_form');
    form.find('[name="sort_by"]').val(value);
    form.submit();
  });

  // お気に入りアイコンのクリックで記事へのリンクが動作しないようにする
  $('.js-study_favorite_toggle_icon_form').on('click', function(event) {
    event.stopPropagation();
  });
});

$(function() {
  $("a[href^='https://www.yayoi-kk.co.jp/lp/freenance/']").on('click', function() {
    $.ajax({
      type: "POST",
      url: "/api/v1/freenance_lp_link"
    });
  });
});
